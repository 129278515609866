import { APIHooksKeysEnum } from 'enums/APIHooksKeysEnum'
import { useQuery } from 'react-query'
import { cartService } from 'services/cart.service'

export const getCart = async (token?: string) => {
  const { data } = await cartService.getCart(token)

  return data
}

export const useGetCart = () => {
  const query = useQuery(APIHooksKeysEnum.GET_CART, () => getCart(), {
    enabled: false,
  })

  return query
}
