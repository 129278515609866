import React from 'react'
import styles from './MegaMenuContent.module.scss'
import { MegaMenuSection } from 'components/Header/components/MegaMenuSection/MegaMenuSection'
import { Button } from 'components/common/Buttons/Button'
import { FreeToolsData, ProToolsData } from 'components/Header/data'
import { FlexRow } from 'components/common/Containers'
import classnames from 'classnames'
import Link from 'next/link'
import { RouteEnum } from 'enums/RouteEnum'

interface Props {
  className?: string
  defaultOpen?: boolean
  onClick: () => void
}

export const MegaMenuContent: React.FC<Props> = ({
  className,
  defaultOpen,
  onClick,
}) => {
  return (
    <div className={classnames(styles.insideWrap, className)}>
      <MegaMenuSection
        title="Free Tools"
        description="
            Explore a limited version of Bishopi Research Intelligence to
            develop your digital strategy
          "
        items={FreeToolsData}
        defaultOpen={defaultOpen}
        onClick={onClick}
      />
      <MegaMenuSection
        title="Pro Tools"
        description="
            Unlock granular and actionable insights to explore your market and
            grow your business
          "
        items={ProToolsData}
        defaultOpen={defaultOpen}
        onClick={onClick}
      />
    </div>
  )
}
