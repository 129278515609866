import React, { HTMLAttributes } from 'react'
import styles from './Flex.module.scss'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Flex: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <div className={classNames(styles.flex, className)} {...props}>
      {children}
    </div>
  )
}
