import styles from './Header.module.scss'

import React, { forwardRef, MutableRefObject } from 'react'
import classNames from 'classnames'

import { NavBar } from './components/NavBar/NavBar'
import { useGetUser } from 'hooks/useGetUser'

interface Props {
  className?: string
}

const Header = forwardRef<HTMLElement, Props>(({ className }, ref) => {
  const { data: user } = useGetUser()

  const headerRef = React.useRef<HTMLElement>()

  React.useEffect(() => {
    const calculateHeight = () => {
      const navbar = headerRef?.current
      if (navbar) {
        const height = navbar.getBoundingClientRect().height
        document.body.style.setProperty(
          '--headerHeight',
          height.toString() + 'px'
        )
      }
    }

    window.addEventListener('resize', calculateHeight)
    calculateHeight()

    return () => {
      window.removeEventListener('resize', calculateHeight)
    }
  }, [])

  return (
    <header
      className={classNames(styles.header, className)}
      ref={(node) => {
        // Reference - https://stackoverflow.com/a/62238917/6475950
        headerRef.current = node
        if (typeof ref === 'function') {
          ref(node)
        } else if (ref) {
          ;(ref as MutableRefObject<HTMLElement>).current = node
        }
      }}
    >
      <NavBar isAuth={Boolean(user)} />
    </header>
  )
})

export default Header
