import React, { useState } from 'react'
import styles from './MegaMenuSection.module.scss'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import { Flex } from 'components/common/Containers/Flex/Flex'
import { H6 } from 'components/common/Texts/H6/H6'
import classNames from 'classnames'
import Image from 'next/image'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import Link from 'next/link'

interface Props {
  defaultOpen?: boolean
  title: string
  description: string
  items: {
    id: number
    src: string
    head: string
    content: string
    href: string
  }[]
  onClick: () => void
}

export const MegaMenuSection: React.FC<Props> = ({
  title,
  description,
  items,
  defaultOpen = true,
  children,
  onClick,
}) => {
  const [open, setOpen] = useState(defaultOpen)
  const toggle = () => setOpen((o) => !o)

  return (
    <div className={styles.menuWrap}>
      <FlexRow onClick={toggle} className={styles.head}>
        <Flex className={styles.content}>
          <H6 className={styles.heading}>{title}</H6>
          <p className={styles.desc}>{description}</p>
        </Flex>
        <Flex className={classNames(styles.chevron, open && styles.chevOpen)}>
          <Image
            alt="close"
            width={12}
            height={6}
            src="/images/megaMenu/chevron-down.png"
          />
        </Flex>
      </FlexRow>

      <div className={classNames(styles.contentWrap, open && styles.openState)}>
        {items.map((tile) => (
          <Link key={tile.id} href={tile.href} passHref>
            <div
              className={styles.tile}
              onClick={() => {
                onClick()
              }}
            >
              <FlexRow justify="flex-start" align="flex-start">
                <Image src={tile.src} alt="icon" width={40} height={40} />
              </FlexRow>
              <FlexColumn className={styles.content}>
                <H6 className={styles.miniHead}>{tile.head}</H6>
                <p>{tile.content}</p>
              </FlexColumn>
            </div>
          </Link>
        ))}

        {children}
      </div>
    </div>
  )
}
