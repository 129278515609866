import React from 'react'
import styles from './EmailForm.module.scss'
import { H2 } from 'components/common/Texts/H2/H2'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import { Form, Formik } from 'formik'
import Input from 'components/common/Input/Input'
import { Button } from 'components/common/Buttons/Button'
import { useSubscribeNewsletter } from 'hooks/useSubscribeNewsletter'
import { notificationController } from 'notificationController/notificationController'
import { FlexRow } from 'components/common/Containers'
import Recaptcha from 'components/common/Recaptcha/Recaptcha'
import * as yup from 'yup'
import { emailEmptyError, emailError, recaptchaError } from 'constants/errors'

interface Props {
  className?: string
}

export const EmailForm: React.FC<Props> = ({ className }) => {
  const { mutate, isLoading } = useSubscribeNewsletter()

  const onSubmit = ({ email, g_recaptcha_response }) => {
    if (email) {
      if (g_recaptcha_response) {
        mutate({ email, g_recaptcha_response })
      } else {
        notificationController.show({
          description: recaptchaError,
          status: 'error',
        })
      }
    } else {
      notificationController.show({
        description: emailError,
        status: 'error',
      })
    }
  }

  const EmailFormSchema = yup.object().shape({
    email: yup.string().required(emailEmptyError).email(emailError),
    g_recaptcha_response: yup.string().required(recaptchaError),
  })

  return (
    <FlexColumn className={className} align="normal" gap="0.5rem">
      <H2>Stay up to date</H2>
      <p>Get updated with all the news, update and upcoming features.</p>

      <Formik
        initialValues={{ email: '', g_recaptcha_response: '' }}
        onSubmit={onSubmit}
        validationSchema={EmailFormSchema}
      >
        {({ setFieldValue }) => (
          <FlexColumn>
            <Form>
              <div className={styles.inputWrapper}>
                <Input
                  size="lg"
                  name="email"
                  placeholder="Enter your email"
                  type="email"
                />
                <Button
                  loading={isLoading}
                  className={styles.button}
                  type="submit"
                >
                  Subscribe Now
                </Button>
              </div>
            </Form>

            <div>
              <Recaptcha
                name="g_recaptcha_response"
                verifyCallback={(response) =>
                  setFieldValue('g_recaptcha_response', response)
                }
              />
            </div>
          </FlexColumn>
        )}
      </Formik>

      <p className={styles.helper}>We never send spam</p>
    </FlexColumn>
  )
}
