import React from 'react'
import styles from './Backdrop.module.scss'
import classnames from 'classnames'

interface Props {
  show?: boolean
  onClick: () => void
}

export const Backdrop: React.FC<Props> = ({ show, onClick }) => {
  return (
    <div
      className={classnames(styles.backDrop, show && styles.show)}
      onClick={onClick}
    />
  )
}
