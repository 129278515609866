export const emailError = 'Please enter a valid email address'
export const emailEmptyError = 'Please enter your email address'

export const passwordLengthError =
  'Your password should be minimum eight characters'
export const passwordSpecialCharacterError =
  'Your password should contain at least one special character'
export const passwordNumberError =
  'Your password should contain at least one number'
export const passwordUpperCaseError =
  'Your password should contain at least one uppercase letter'
export const passwordLowerCaseError =
  'Your password should contain at least one lowercase letter'
export const passwordEmptyError = 'Please enter a password'

export const confirmPasswordMatchError = "Passwords don't match"
export const confirmPasswordEmptyError = 'Please confirm your password'

export const fullNameError = 'Please enter your full name'
export const fullNameUrlError = 'Please enter a valid name without urls'
export const fullNameEmptyError = 'Please enter your full name'

export const termsError = 'Please accept the terms and conditions'

export const recaptchaError = 'Please verify that you are not a robot'

export const nameserverEmptyError = 'Must not be empty'

export const firstNameEmptyError = 'Please enter your first name'

export const lastNameEmptyError = 'Please enter your last name'

export const auctionUsernameEmptyError = 'Please enter your auction username'

export const phoneError = 'Please enter a valid phone number'
export const phoneEmptyError = 'Please enter your phone number'

export const countryEmptyError = 'Please select your country'

export const cityEmptyError = 'Please enter your city'

export const stateEmptyError = 'Please enter your state'

export const addressEmptyError = 'Please enter your address'

export const zipcodeEmptyError = 'Please enter your zipcode'

export const organizationEmptyError = 'Please enter your organization'

export const messageEmptyError = 'Please enter your message'

export const domainEmptyError = 'Domain is required'
export const domainInvalidError = 'Domain is not valid'
export const domainError = 'Please, enter a valid domain'

export const titleEmptyError = 'Please enter title'

export const categoryEmptyError = 'Please enter category'

export const thumbnailEmptyError = 'Please choose thumbnail'

export const contentEmptyError = 'Please enter content'

export const shortDescriptionEmptyError = 'Please enter short description'

export const notAnAdminCantPublishError =
  'You are not an admin or post already published'

export const industryRolesError = 'Please select industry role'

export const referrerError = 'Please select referrer'

export const metaDescriptionEmptyError = 'Please enter meta description'

export const canonicalEmptyError = 'Please enter canonical'

export const keywordsEmptyError = 'Please enter keywords'
