import React, { forwardRef, lazy } from 'react'
import { ErrorMessage } from 'formik'
import { FlexColumn } from '../Containers'
import ReCAPTCHA from 'react-google-recaptcha'

interface Props {
  name?: string
  verifyCallback?: (response: string) => void
}

const Recaptcha = forwardRef<any, Props>(
  ({ name, verifyCallback, ...props }, ref) => {
    return (
      <FlexColumn gap="5px">
        <ReCAPTCHA
          ref={ref}
          sitekey={process.env.NEXT_PUBLIC_G_CAPTCHA_KEY}
          onChange={verifyCallback}
          {...props}
        />
        <ErrorMessage className="error" name={name} component="span" />
      </FlexColumn>
    )
  }
)

export default Recaptcha
