import React, { HTMLAttributes, forwardRef } from 'react'
import styles from './FlexColumn.module.scss'
import classNames from 'classnames'
import * as CSS from 'csstype'

interface Props extends HTMLAttributes<HTMLDivElement> {
  justify?: CSS.Property.JustifyContent
  align?: CSS.Property.AlignItems
  gap?: string
}

export const FlexColumn = forwardRef<HTMLDivElement, Props>(
  ({ children, justify, align, gap, className, ...props }, ref) => {
    const style = {
      '--align': align,
      '--justify': justify,
      '--gap': gap,
    } as React.CSSProperties

    return (
      <div
        style={style}
        className={classNames(className, styles.flexColumn)}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)
