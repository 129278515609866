import React, { HTMLAttributes } from 'react'
import styles from './FlexRow.module.scss'
import classNames from 'classnames'
import * as CSS from 'csstype'

interface Props extends HTMLAttributes<HTMLDivElement> {
  justify?: CSS.Property.JustifyContent
  align?: CSS.Property.AlignItems
  gap?: string
  wrap?: CSS.Property.FlexWrap
}

export const FlexRow: React.FC<Props> = ({
  children,
  justify,
  align,
  gap,
  className,
  wrap,
  ...props
}) => {
  const style = {
    '--align': align,
    '--justify': justify,
    '--gap': gap,
    '--wrap': wrap,
  } as React.CSSProperties

  return (
    <div
      style={style}
      className={classNames(styles.flexRow, className)}
      {...props}
    >
      {children}
    </div>
  )
}
