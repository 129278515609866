import React from 'react'
import { Flex } from 'components/common/Containers/Flex/Flex'
import styles from './MegaMenu.module.scss'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import classNames from 'classnames'
import { Hamburger } from 'components/common/Hamburger/Hamburger'
import { Backdrop } from 'components/common/Backdrop/Backdrop'
import { MegaMenuContent } from 'components/Header/components/MegaMenuContent/MegaMenuContent'
import { Button } from 'components/common/Buttons/Button'
import Link from 'next/link'
import { RouteEnum } from 'enums/RouteEnum'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  className?: string
}

export const MegaMenu: React.FC<Props> = ({ open, setOpen, className }) => {
  const close = () => {
    setOpen(false)
  }
  return (
    <Flex
      className={classNames(styles.container, open && styles.open, className)}
    >
      <div
        className={classNames(
          styles.megaMenuWrap,
          open && styles.megaMenuWrapOpen
        )}
      >
        <div className={styles.megaMenuMainContent}>
          <FlexRow className={styles.close} gap="8px">
            <Hamburger active={open} setActive={setOpen} />
            <Button mode="text" className={styles.closeButton} onClick={close}>
              Close
            </Button>
          </FlexRow>
          <MegaMenuContent defaultOpen={true} onClick={close} />
        </div>

        <div className={styles.brandWrap}>
          <FlexRow className={styles.buttonArea} gap="46px">
            <p>
              Bishopi’s business solutions power your teams to make strategic
              and tactical decisions that drive ROI and revenues. Please choose
              one of our products below:
            </p>
            <Link href={RouteEnum.PRICING} passHref>
              <Button>Pricing</Button>
            </Link>
          </FlexRow>
        </div>
      </div>
      <Backdrop onClick={close} show={open} />
    </Flex>
  )
}
