import React from 'react'
import classNames from 'classnames'
import { Dimension } from 'models/dimension.interface'
import { getNormalizedDimension } from 'utils/getNormalizedDimension'
import styles from './Card.module.scss'

interface Props {
  className?: string
  padding?: Dimension
  children?: React.ReactNode
  withBoxShadow?: boolean
}

const Card = React.forwardRef<HTMLDivElement, Props>(
  ({ className, padding, withBoxShadow = true, children }, ref) => {
    const style =
      padding &&
      ({
        '--card-padding': getNormalizedDimension(padding),
        '--card-boxshadow': withBoxShadow
          ? '0px 4px 26px rgba(112, 144, 176, 0.17)'
          : 'none',
      } as React.CSSProperties)

    return (
      <div
        ref={ref}
        style={style}
        className={classNames(className, styles.card)}
      >
        {children}
      </div>
    )
  }
)

export default Card
