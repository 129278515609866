import { useQuery } from 'react-query'
import { APIHooksKeysEnum } from 'enums/APIHooksKeysEnum'
import { notificationController } from 'notificationController/notificationController'
import { accountService } from 'services/account.service'
import { setUser } from '@sentry/nextjs'
export const fetchUser = async (token?: string) => {
  const { data } = await accountService.getUser(token)
  setUser({
    email: data.email,
    username: data.auction_username,
    segment: JSON.stringify({
      subscription: data?.subscription,
      has_valid_payment: data.has_valid_payment,
    }),
  })
  return data
}

export const useGetUser = () => {
  const { data, isLoading, error, refetch } = useQuery(
    APIHooksKeysEnum.GET_USER,
    () => fetchUser(),
    {
      enabled: false,
      onError: (error) => {
        notificationController.show({
          description: (error as Error).message || 'Failed to fetch user',
          status: 'error',
        })
      },
    }
  )

  return { data, isLoading, error, refetch }
}
