// input 12, output 12px
// input 12px, output 12px
// input 12rem, output 12rem
// input [12, 12], output 12px 12px

import { Dimension } from 'models/dimension.interface'

export const getNormalizedDimension = (dimension: Dimension): string =>
  typeof dimension === 'number'
    ? `${dimension}px`
    : (Array.isArray(dimension) && `${dimension[0]}px ${dimension[1]}px`) ||
      dimension.toString()
