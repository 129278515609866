import React, { useEffect, useState } from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import styles from './NavBar.module.scss'
import classNames from 'classnames'
import { Hamburger } from 'components/common/Hamburger/Hamburger'
import { Button } from 'components/common/Buttons/Button'
import Image from 'next/image'
import { MegaMenu } from 'components/Header/components/MegaMenu/MegaMenu'
import Link from 'next/link'
import { RouteEnum } from 'enums/RouteEnum'
import { MegaMenuContent } from 'components/Header/components/MegaMenuContent/MegaMenuContent'
import { Container, FlexRow, FlexColumn } from 'components/common/Containers'
import { useGetCart } from 'hooks/useGetCart'
import Dropdown from 'components/common/Dropdown/Dropdown'
import { Card } from 'components/common/Card'
import useComponentVisible from 'hooks/useComponentVisible'
import { Notification } from 'components/common/Notification/Notification'
import dynamic from 'next/dynamic'

interface Props {
  isAuth: boolean
}

const Cart = dynamic(() => import('components/Cart/Cart'))

export const NavBar: React.FC<Props> = ({ isAuth }) => {
  const [active, setActive] = React.useState(false)
  const [megaMenuActive, setMegaMenuActive] = React.useState(false)
  const [isCartOpen, setIsCartOpen] = useState(false)

  const { data: cart, refetch: getCart } = useGetCart()

  useEffect(() => {
    if (isAuth) {
      getCart()
    }
  }, [getCart, isAuth])

  const shouldShowCart = isAuth && cart?.count > 0

  const hide = () => setActive(false)

  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false)

  const handleOpen = () => {
    setIsComponentVisible((open) => !open)
  }
  const cartButton = (
    <Button
      className={classNames(styles.cart, isCartOpen && styles.active)}
      mode="icon"
      onClick={() => setIsCartOpen(true)}
    >
      <Notification text={cart?.count}>
        <AiOutlineShoppingCart />
      </Notification>
    </Button>
  )

  return (
    <nav className={classNames(styles.container, active && styles.opened)}>
      <MegaMenu
        className={styles.onlyLgUp}
        open={megaMenuActive}
        setOpen={setMegaMenuActive}
      />

      {shouldShowCart && (
        <Cart
          cart={cart}
          isOpen={isCartOpen}
          onClose={() => setIsCartOpen(false)}
        />
      )}

      <Container>
        <div className={styles.content}>
          <div className={styles.logoHamContainer}>
            <Link prefetch={false} href={RouteEnum.HOME} passHref>
              <div className={styles.logo}>
                <Image
                  src="/logos/logo.svg"
                  width="131"
                  height="25"
                  alt="logo"
                  loading="eager"
                />
              </div>
            </Link>

            <FlexRow gap="0px" align="center">
              {shouldShowCart && (
                <div className={styles.onlyLgDown}>{cartButton}</div>
              )}

              <div className={styles.onlyLgDown}>
                <Hamburger active={active} setActive={setActive} />
              </div>
            </FlexRow>
          </div>

          <div className={styles.contentInner}>
            <ul className={classNames(styles.navItems)}>
              <MegaMenuContent
                className={styles.onlyLgDown}
                defaultOpen={false}
                onClick={() => setMegaMenuActive((t) => false)}
              />
              <li
                onClick={() => setMegaMenuActive((t) => !t)}
                className={classNames(
                  styles.tools,
                  styles.onlyLgUp,
                  styles.noUnderline
                )}
              >
                <span>Features</span>
                <Image
                  alt="close"
                  width={12}
                  height={6}
                  src="/images/megaMenu/chevron-down.png"
                />
              </li>
              <li onClick={hide}>
                <Link prefetch={false} href={RouteEnum.DOMAINS} passHref>
                  Domains
                </Link>
              </li>
              {/* <li onClick={hide}>
                <Link prefetch={false} href={RouteEnum.AUCTIONS} passHref>
                  Auctions
                </Link>
              </li> */}
              <li onClick={hide}>
                <Link prefetch={false} href={RouteEnum.PRICING} passHref>
                  Pricing
                </Link>
              </li>
              <li onClick={hide}>
                <Link prefetch={false} href={RouteEnum.CONTACT_US} passHref>
                  Contact
                </Link>
              </li>
              <li className={styles.noUnderline}>
                <Dropdown
                  isOpen={isComponentVisible}
                  withArrow={false}
                  withClose={false}
                  placement="bottom-start"
                  triggerNode={
                    <div ref={ref} onClick={handleOpen}>
                      <FlexRow gap="0.5rem" align="center">
                        Resources
                        <Image
                          alt="close"
                          width={12}
                          height={6}
                          src="/images/megaMenu/chevron-down.png"
                        />
                      </FlexRow>
                    </div>
                  }
                >
                  <Card className={styles.resourcesCard} padding={[8, 0]}>
                    <FlexColumn gap="0">
                      <Link prefetch={false} href={RouteEnum.BLOG} passHref>
                        <div>
                          <span>Blog</span>
                        </div>
                      </Link>

                      <Link prefetch={false} href={RouteEnum.APIS} passHref>
                        <div>
                          <span>API Access</span>
                        </div>
                      </Link>
                      <Link prefetch={false} href={RouteEnum.AFFILIATE_PROGRAM}>
                        <div>
                          <span>Affiliate Program</span>
                        </div>
                      </Link>
                    </FlexColumn>
                  </Card>
                </Dropdown>
              </li>
            </ul>

            <div
              className={classNames(styles.navItems, styles.ctaButtons)}
              onClick={() => setActive(false)}
            >
              {shouldShowCart && (
                <div className={styles.onlyLgUp}>{cartButton}</div>
              )}
              {!isAuth ? (
                <>
                  <Link
                    prefetch={false}
                    href={RouteEnum.LOGIN}
                    passHref
                    className={styles.onlyLgUp}
                  >
                    <Button mode="text">Login</Button>
                  </Link>
                  <Link
                    prefetch={false}
                    href={RouteEnum.LOGIN}
                    passHref
                    className={styles.onlyLgDown}
                  >
                    <Button block outlined={true}>
                      Login
                    </Button>
                  </Link>
                  <Link prefetch={false} href={RouteEnum.SIGNUP} passHref>
                    <Button block>Sign Up</Button>
                  </Link>
                </>
              ) : (
                <Link
                  prefetch={false}
                  href={RouteEnum.DASHBOARD_CUSTOMER_PORTAL}
                  passHref
                >
                  <Button block>Dashboard</Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </Container>
    </nav>
  )
}
