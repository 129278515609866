import React, { InputHTMLAttributes, useState } from 'react'
import classNames from 'classnames'
import styles from './Input.module.scss'
import { ErrorMessage, Field } from 'formik'
import { FlexColumn } from '../Containers'
import useComponentVisible from 'hooks/useComponentVisible'

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'lg'
  wrapperClassName?: string
  className?: string
  isFormik?: boolean
  withErrorMessage?: boolean
  label?: string
  defaultValues?: string[]
  onDefaultValueClick?: (value: string) => void
  renderPrefix?: () => React.ReactNode
  errorClassName?: string,
  inputErrorClassName?: string
}

const Input: React.FC<InputProps> = ({
  size,
  wrapperClassName,
  name,
  label,
  isFormik = true,
  withErrorMessage = true,
  className,
  disabled,
  defaultValues,
  onDefaultValueClick,
  renderPrefix,
  errorClassName,
  inputErrorClassName,
  ...props
}) => {
  const [selectedDefaultValues, setSelectedDefaultValues] = useState<string[]>(
    []
  )

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  return (
    <div
      ref={ref}
      className={classNames(styles.inputWrapper, wrapperClassName)}
    >
      {renderPrefix && (
        <div className={styles.prefixWrapper}>{renderPrefix()}</div>
      )}

      {defaultValues && (
        <FlexColumn
          gap="0"
          align="flex-start"
          className={classNames(
            styles.defaultValuesWrapper,
            isComponentVisible && styles.active
          )}
        >
          {defaultValues
            .filter(
              (value) =>
                !selectedDefaultValues.some((defaultValue) =>
                  defaultValue.includes(value)
                )
            )
            .map((value) => (
              <span
                key={value}
                className={styles.defaultValue}
                onClick={() => {
                  onDefaultValueClick(value)
                  setSelectedDefaultValues((selected) => [...selected, value])
                }}
              >
                {value}
              </span>
            ))}
        </FlexColumn>
      )}
      {isFormik ? (
        <FlexColumn gap="5px">
          {label && (
            <label className={styles.label} htmlFor={name}>
              {label}
            </label>
          )}

          <Field name={name}>
            {({ field, form }: any) => (
              <input
                id={name}
                className={classNames(
                  className,
                  styles.input,
                  disabled && styles.disabled,
                  form.errors[name] && styles.error,
                  renderPrefix && styles.prefixed,
                  size === 'lg' && styles.lg,
                  form.errors[name] && inputErrorClassName
                )}
                {...(defaultValues && {
                  onClick: () => setIsComponentVisible(!isComponentVisible),
                })}
                {...props}
                {...field}
              />
            )}
          </Field>

          {withErrorMessage && (
            <ErrorMessage
              className={classNames('error', errorClassName)}
              name={name}
              component="span"
            />
          )}
        </FlexColumn>
      ) : (
        <input
          className={classNames(
            className,
            styles.input,
            disabled && styles.disabled,
            renderPrefix && styles.prefixed,
            disabled && styles.disabled,
            size === 'lg' && styles.lg
          )}
          {...(defaultValues && {
            onClick: () => setIsComponentVisible(!isComponentVisible),
          })}
          {...props}
        />
      )}
    </div>
  )
}

export default Input
