import React from 'react'
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverProps,
} from '@chakra-ui/react'
import styles from './Dropdown.module.scss'

interface DropdownProps extends PopoverProps {
  triggerNode: React.ReactNode
  title?: React.ReactNode
  withArrow?: boolean
  withClose?: boolean
  isOpen?: boolean
}

const Dropdown: React.FC<DropdownProps> = ({
  triggerNode,
  title,
  withArrow = true,
  withClose = true,
  children,
  isOpen,
  ...props
}) => {
  return (
    <Popover {...props}>
      <PopoverTrigger>{triggerNode}</PopoverTrigger>
      <PopoverContent className={styles.content}>
        {withArrow && <PopoverArrow />}
        {withClose && <PopoverCloseButton />}
        {title && <PopoverHeader>{title}</PopoverHeader>}
        {isOpen && <PopoverBody>{children}</PopoverBody>}
      </PopoverContent>
    </Popover>
  )
}

export default Dropdown
