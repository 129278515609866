import React, { HTMLAttributes } from 'react'
import styles from './H2.module.scss'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLHeadingElement> {}

export const H2: React.FC<Props> = ({ className, children, ...props }) => {
  return (
    <h2 className={classNames(styles.h2, className)} {...props}>
      {children}
    </h2>
  )
}
