import { AxiosError } from 'axios'
import { notificationController } from 'notificationController/notificationController'
import { useMutation } from 'react-query'
import { newsletterService } from 'services/newsletter.service'

const subscribeNewsletter = async ({ email, g_recaptcha_response }) => {
  const { data } = await newsletterService.subscribeNewsletter({
    email,
    g_recaptcha_response,
  })

  return data
}

export const useSubscribeNewsletter = () => {
  const mutation = useMutation(subscribeNewsletter, {
    onSuccess: (data) => {
      notificationController.show({
        description:
          data?.message || 'Succesfully subscribed to our newsletter',
        status: 'success',
      })
    },
    onError: (error: any) => {
      notificationController.show({
        description:
          error?.message?.email ||
          error?.message?.g_recaptcha_response ||
          error?.message ||
          'Failed to subscribe to newsletter',
        status: 'error',
      })
    },
  })

  return mutation
}
