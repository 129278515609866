import React from 'react'
import { Footer } from 'components/Footer/Footer'
import Header from 'components/Header/Header'

export interface MainLayoutProps {
  className?: string
  headerClassName?: string
  headerRef?: React.Ref<HTMLElement>
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  className,
  headerClassName,
  headerRef,
}) => {
  return (
    <>
      <Header className={headerClassName} ref={headerRef} />
      <main className={className}>{children}</main>
      <Footer />
    </>
  )
}

export default MainLayout
