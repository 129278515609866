import React, { HTMLAttributes, forwardRef } from 'react'
import styles from './Container.module.scss'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
  containerClassName?: string
}

export const Container = forwardRef<HTMLDivElement, Props>(({
  className,
  children,
  ...props
}, ref) => {
  return (
    <div
      className={classNames(className, styles.container)}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  )
})