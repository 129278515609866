import React from 'react'
import styles from './Notification.module.scss'

interface Props {
  color?: string
  text: React.ReactNode
}

export const Notification: React.FC<Props> = ({ text, color, children }) => {
  const style = {
    '--notification-bg': color || 'var(--blue)',
    '--notification-color': color || 'var(--white)',
  }

  return (
    <div
      style={style as React.CSSProperties}
      className={styles.notificationWrapper}
    >
      <div className={styles.icon}>{text}</div>
      {children}
    </div>
  )
}
