import axios from 'axios'
import { SubscribeNewsletterResponse } from 'models/newsletter.interface'
import { BishopiBaseService } from './bishopi-base.service'

class NewsletterService extends BishopiBaseService {
  subscribeNewsletter({ email, g_recaptcha_response }) {
    return axios.post<SubscribeNewsletterResponse>(
      this.getPath('/newsletter/subscribe/'),
      {
        email,
        g_recaptcha_response,
      }
    )
  }

  unsubscribeFromNewsletter(token: string) {
    return axios.post(this.getPath(`/newsletter/opt-out/email/${token}`), {})
  }

  resubscribeToNewsletter(token: string) {
    return axios.post(this.getPath(`/newsletter/resubscribe/${token}`), {})
  }
}

export const newsletterService = new NewsletterService()
