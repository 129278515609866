import axios from 'axios'
import {
  CancelPlanResponse,
  CardInfo,
  User,
  NameserversDetails,
  UpdateNameserversResponse,
  UpdateUserResponse,
  ContactRequestDetails,
  ContactRequestResponse,
  AddCardDetails,
  AddCardResponse,
  WHOISResponse,
  WHOISUser,
  SubscribePlanDetails,
  SubscribePlanResponse,
  ApplyCouponResponse,
  AuthorizationDetails,
  VerifyTokenResponse,
  ReferralEmailRequest,
  ReferralEmailResponse,
  EditPaymentMethod,
  ReferralPayouts,
  RequestPayout,
  CancelPlanReason,
  Subscription3DSResponse,
  Verify3DSPaymentDetails,
} from 'models/account.interface'
import { BishopiBaseService } from './bishopi-base.service'

class AccountService extends BishopiBaseService {
  getUser(token?: string) {
    return axios.get<User>(this.getPath(`/accounts/current-user/`), {
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    })
  }

  getCard(token?: string) {
    return axios.get<CardInfo | []>(this.getPath(`/payments/card/`), {
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    })
  }

  addCard(details: AddCardDetails) {
    return axios.put<AddCardResponse>(this.getPath('/payments/card/'), details)
  }

  savePaypalAuthorization(details: AuthorizationDetails) {
    return axios.post(
      this.getPath('/payments/paypal/authorization_success'),
      details
    )
  }

  cancelPlan(cancelDetails: CancelPlanReason) {
    return axios.post<CancelPlanResponse>(
      this.getPath(`/payments/account/plan/cancel/`),
      {
        confirm: true,
        reason: cancelDetails.reason,
      }
    )
  }

  generateAPIKey() {
    return axios.post(this.getPath('/accounts/generate-api-key/'), {})
  }

  updateNameservers(details: NameserversDetails) {
    return axios.put<UpdateNameserversResponse>(
      this.getPath('/accounts/update-nameservers/'),
      details
    )
  }

  updateUser(details: User) {
    return axios.put<UpdateUserResponse>(
      this.getPath(`/accounts/current-user/`),
      details
    )
  }

  getWHOIS() {
    return axios.get<WHOISResponse>(this.getPath('/accounts/update-whois/'))
  }

  updateWHOIS(details: WHOISUser) {
    return axios.put<WHOISResponse>(
      this.getPath('/accounts/update-whois/'),
      details
    )
  }

  contactRequest(details: ContactRequestDetails) {
    return axios.post<ContactRequestResponse>(
      this.getPath(`/contact/`),
      details
    )
  }

  subscribePlan(details: SubscribePlanDetails) {
    return axios.put<SubscribePlanResponse | Subscription3DSResponse>(
      this.getPath('/payments/account/plan/'),
      details
    )
  }

  verify3DSPayment(details: Verify3DSPaymentDetails) {
    return axios.get<any>(
      this.getPath(
        `/payments/3ds-result/?payment_intent=${details.paymentIntent}`
      )
    )
  }

  applyCoupon(coupon: string) {
    return axios.post<ApplyCouponResponse>(this.getPath('/payments/coupon/'), {
      promocode: coupon,
    })
  }

  verifyToken(token: string) {
    return axios.get<VerifyTokenResponse>(
      this.getPath(`/accounts/auth/verification/${token}`)
    )
  }

  sendReferralEmail(body: ReferralEmailRequest) {
    return axios.post<ReferralEmailResponse>(
      this.getPath('/affiliates/send-invite-email/'),
      body
    )
  }
  getMyReferrals() {
    return axios.get<ReferralPayouts>(
      this.getPath('/affiliates/get-my-referrals/')
    )
  }
  editPaymentMethod(body: EditPaymentMethod) {
    return axios.post(this.getPath('/affiliates/edit-payment-method/'), body)
  }
  requestPayoutMethod(body: RequestPayout) {
    return axios.post(this.getPath('/affiliates/request-payout/'), body)
  }

  trackAffiliateClick(inviteToken: string) {
    return axios.get(
      this.getPath(
        `/affiliates/track-affiliate-clicks?invite_token=${inviteToken}`
      )
    )
  }
}

export const accountService = new AccountService()
