import axios from 'axios'
import {
  AddToCartDetails,
  AddToCartResponse,
  Cart,
  CheckoutCartPayload,
} from 'models/cart.interface'
import { BishopiBaseService } from './bishopi-base.service'

class CartService extends BishopiBaseService {
  getCart(token?: string) {
    return this.get<Cart>(this.getPath('/carts/pending-order/'), token)
  }

  deleteFromCart(domain_id: string) {
    return axios.delete(this.getPath('/carts/remove-cart-item/'), {
      data: {
        domain_id,
      },
    })
  }

  addToCart(details: AddToCartDetails) {
    return axios.post<AddToCartResponse>(
      this.getPath('/carts/add-to-cart/'),
      details
    )
  }

  checkoutCart(payload: CheckoutCartPayload) {
    return axios.post(this.getPath('/carts/checkout-cart/'), payload)
  }
}

export const cartService = new CartService()
