import React, { HTMLAttributes } from 'react'
import styles from './H6.module.scss'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLHeadingElement> {}

export const H6: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <h6 className={classNames(styles.h6, className)} {...props}>
      {children}
    </h6>
  )
}
