import axios from 'axios'

export class BishopiBaseService {
  protected getPath(path: string) {
    return `${process.env.NEXT_PUBLIC_BASE_URL}${path}`
  }

  public get<T>(path: string, token?: string) {
    return axios.get<T>(path, {
      headers: { Authorization: token ? `Token ${token}` : '' },
    })
  }
}
